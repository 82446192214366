import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../store/store";
import { useNavigate, useLocation } from "react-router-dom";
import "./style.scss";
import queryString from "query-string";
import jwt_decode from "jwt-decode";
import { setGpsPoints } from "../../store/plot/plotSlice";
import { Toolbar } from "./../../components/toolbar";
import { AppState } from "../../store/store";
import rest from "../../rest";
import * as plotAPI from "./../../api/netRail/plot";
import {
  setAllPlans,
  setExternalAccessToken,
  setSelectedMeasurement,
  setSelectedPlan,
} from "../../store/scheduler/schedulerSlice";
import { MetaData } from "../../components/dashboard/metaData";
import { computeResolution } from "../../components/visualization/groupChart/chartConfig";
import { setNotification } from "../../store/user/userSlice";
import * as plansAPI from "./../../api/netRail/plans";
import { ChartList } from "../../components/visualization/chartList";
import { GpsVisualization } from "../../components/visualization/gpsVisualizationContainer";
import { HandleFilesPopup } from "../../components/handleFiles/handleFiles";
import { CurrentLongitudinalLevelVisualization } from "../../components/visualization/currentLongitudinalLevelVisualization";
import { useTranslation } from "react-i18next";
import { ToggleObjects } from "../../components/generic/toggleObjects/toggleObjects";
import { ChartTabs } from "../../components/dashboard/chartTabs";
import SvgAnalysisGraph from "../../components/componentIcons/AnalysisGraph";
import TrackGeometryIcon from "../../components/componentIcons/TrackGeomertryIcon";
import { tabProps } from "../dashboard/page";
import { Spinner } from "../../components/spinner";

export const DashboardViewOnly: React.FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const screenWidth = useSelector(
    (state: AppState) => state.scheduler.screenWidth
  );

  const [showFiles, setShowFiles] = useState<any>();
  const selectedMeasurement = useSelector(
    (state: AppState) => state.scheduler.selectedMeasurement
  );
  useEffect(() => {
    const rawToken = queryString.parse(location.search).access_token;
    if (rawToken == null) {
      navigate("/login", { state: location.pathname});
    }
    let decodedToken: any;
    try {
      decodedToken = jwt_decode(rawToken as string);
    } catch (error) {
      navigate("/login");
    }

    dispatch(setExternalAccessToken(rawToken as string));

    const measurementID = decodedToken.sub;

    const date = Math.floor(new Date().getTime() / 1000);
    const isExpired = decodedToken.exp < date ? true : false;
    if (isExpired) {
      navigate("/login", { state: location.pathname});
      window.alert(t("dashboardView.linkExpired"));
      return;
    }

    dispatch(setSelectedMeasurement(decodedToken.sub));

    rest
      .get(`/measurements/${measurementID}`)
      .then(async (response) => {
        // Set Excel file
        // setFiles(response.data.files);
        // console.log("response.data.files", response.data.files);

        const measurement = response.data;

        // get gps data
        const xMin = +measurement.startAtKm * 1000 + +measurement.startAtMeter;
        const xMax = +measurement.endAtKm * 1000 + +measurement.endAtMeter;
        const resolution = computeResolution(xMin, xMax, screenWidth);

        try {
          const res = await plotAPI.getGpsData(
            measurement.id,
            xMin,
            xMax,
            resolution,
            "gps",
            ["tick", "objects", "notes", "class"]
          );

          dispatch(setGpsPoints(res));
        } catch {
          setNotification({
            message: t("dashboardView.noGPS"),
            style: "info",
            open: true,
          });
        }
        try {
          const accessablePlan = await plansAPI.getPlan(measurement.planID);
          accessablePlan.measurements = [measurement];
          dispatch(setSelectedPlan(accessablePlan.id));
          dispatch(setAllPlans([accessablePlan]));
        } catch {
          setNotification({
            message: t("dashboardView.cannotFetchPlan"),
            style: "info",
            open: true,
          });
        }
      })
      .catch((error) => {
        console.log("ERROR:", error);
      });
  }, []);

  const height = useSelector((state: AppState) => state.scheduler.screenHeight);
  const mainView = useRef(true);

  const tabs: tabProps[] = [
    {
      component: (
        <div>
          <ChartList key={t("dashboard.trackGeometry")} mainView={mainView} />
        </div>
      ),
      title: t("dashboard.trackGeometry"),
      icon: <TrackGeometryIcon />,
      iconToTheLeft: false,
    },
    {
      component: (
        <div>
          <CurrentLongitudinalLevelVisualization mainView={mainView} />
        </div>
      ),
      title: t("dashboard.railLoweringAnalysis"),
      icon: (
        <div style={{ height: "24px", width: "24px", marginRight: "5px" }}>
          <SvgAnalysisGraph />
        </div>
      ),
      iconToTheLeft: true,
    },
  ];
  
  if (selectedMeasurement === "-1") {
    return (<Spinner />);
  } else {
    return (
      <div className="DashboardContainer">
        <header className="DashboardToolbar">
          <Toolbar>
            <h5>{t("common.measurements")}</h5>
          </Toolbar>
        </header>
        <div className="DashboardContentContainer">
          {height >= 450 ? <MetaData /> : null}
          <div className="DashboardMain">
            <ToggleObjects selectedMeasurement={selectedMeasurement} />
            <GpsVisualization selectedMeasurement={selectedMeasurement} />
            <ChartTabs tabs={tabs} />
            <HandleFilesPopup setShow={setShowFiles} show={showFiles} />
          </div>
        </div>
      </div>
    );
  }
 
};
