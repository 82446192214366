import { fetchAuthSession } from "@aws-amplify/auth";
import axios from "axios";

/**UNCOMMENT BELOW FOR DEV API */
// const l = document.location;
// Replace node.js development server port 3000 with 8080. Otherwise, just keep the port as is.

const baseURL = process.env.REACT_APP_API_URL; //"http://localhost:8080/api/"; //

const instance = axios.create({
  baseURL: baseURL,
});
instance.interceptors.request.use(async function (config) {
  try {
    config.headers.Authorization = (
      await fetchAuthSession()
    ).tokens?.idToken?.toString();
  } catch (reason) {
    console.error(reason);
  }

  return config;
});

export default instance;
