import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { Input } from "@progress/kendo-react-inputs";
import {
  FetchUserAttributesOutput,
  confirmSignIn,
  getCurrentUser,
} from "aws-amplify/auth";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { updateUserProperty } from "../../store/user/userSlice";
import { useAppDispatch } from "../../store/store";
import { SignInSteps } from "../../pages/login/page";
import { CustomInputUser } from "../manageUsers/updateUserForm";
import * as usersAPI from "../../api/netRail/users";
import {
  newPasswordValidator,
  phoneNumberValidator,
} from "../../helpers/validators";

interface NewPasswordFormProps {
  handleError: (
    err: any,
    setAuthError: React.Dispatch<React.SetStateAction<string>>,
    code?: number
  ) => void;
  setNextStep: React.Dispatch<React.SetStateAction<SignInSteps | undefined>>;
}

interface NewPasswordFieldProps {
  phoneNumber: string;
  name: string;
  familyName: string;
  newPassword: string;
  email: string;
}

export const NewPasswordForm: React.FunctionComponent<NewPasswordFormProps> = ({
  handleError,
  setNextStep,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [hasBeenPressed, setHasBeenPressed] = useState(false);
  const [authError, setAuthError] = useState("");
  const [viewNewPassword, setViewNewPassword] = useState(false);
  const [initialValue, setInitialValues] = useState<FetchUserAttributesOutput>({
    phoneNumber: "+46",
  });

  const newPasswordRequiredCallback = useCallback(
    async (dataItem: NewPasswordFieldProps) => {
      const userAttributes = {
        phone_number: dataItem.phoneNumber,
      };
      try {
        const { isSignedIn, nextStep } = await confirmSignIn({
          challengeResponse: dataItem.newPassword,
          options: {
            userAttributes,
          },
        });

        const { username, userId, signInDetails } = await getCurrentUser();

        const resp = await usersAPI.patchUser(
          userId,
          {
            phone: dataItem.phoneNumber,
          }
        );
        dispatch(updateUserProperty(resp));

        setNextStep(nextStep.signInStep);
      } catch (err) {
        console.log("err", err);
      }
    },
    [handleError]
  );

  const form = (formRenderProps: FormRenderProps) => {
    const { valid, valueGetter } = formRenderProps;

    const passwordMatch =
      valueGetter("newPassword") === valueGetter("repeatPassword");
    return (
      <FormElement className="LoginForm">
        {/* <Field
          name={"name"}
          component={CustomInputUser}
          label={t("loginPage.firstname")}
          validator={(v) => notEmptyValidator(t, v)}
        />
        <Field
          name={"familyName"}
          component={CustomInputUser}
          label={t("loginPage.surname")}
          validator={(v) => notEmptyValidator(t, v)}
        /> */}
        <Field
          name={"phoneNumber"}
          component={CustomInputUser}
          label={t("loginPage.phoneNumber") + "*"}
          validator={(v) => phoneNumberValidator(t, v)}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            position: "relative",
          }}
        >
          <Field
            name={"newPassword"}
            component={CustomInputUser}
            type={viewNewPassword ? "text" : "password"}
            label={t("loginPage.chooseNewPassword")}
            validator={(v) => newPasswordValidator(t, v)}
          />
          <span
            className="k-icon k-font-icon  k-font-icon k-i-preview"
            style={
              viewNewPassword
                ? {
                    fontSize: "16px",
                    backgroundColor: "var(--primary-color)",
                    color: "white",
                    borderRadius: "6px",
                    right: "0",
                    position: "absolute",
                    top: "26px",
                  }
                : {
                    fontSize: "16px",
                    borderRadius: "6px",
                    right: "0",
                    position: "absolute",
                    color: "var(--primary-color)",
                    top: "26px",
                  }
            }
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setViewNewPassword(!viewNewPassword);
            }}
            onMouseLeave={() => {
              setViewNewPassword(false);
            }}
          />
        </div>
        <Field
          name={"repeatPassword"}
          component={Input}
          type={"password"}
          label={t("user.repeatPassword")}
          valid={passwordMatch}
        />
        {!passwordMatch ? (
          <div
            style={{
              color: "red",
              height: "11px",
              fontSize: "10px",
            }}
          >
            {t("loginPage.passwordDontMatch")}
          </div>
        ) : (
          <div style={{ height: "11px" }} />
        )}
        <div style={{ color: "red" }}>
          {authError && authError.length > 0 ? authError : ""}{" "}
        </div>
        <div className="k-form-buttons">
          <button
            type={"submit"}
            className="LoginButton BUTTON"
            disabled={
              !formRenderProps.allowSubmit ||
              hasBeenPressed ||
              !valid ||
              !passwordMatch
            }
            style={
              !formRenderProps.allowSubmit ||
              hasBeenPressed ||
              !valid ||
              !passwordMatch
                ? { opacity: 0.5 }
                : {}
            }
            // onClick={() => {
            //   if (timer && newPasswordRequired) {
            //     clearTimeout(timer);
            //   }
            // }}
          >
            OK
          </button>
        </div>
      </FormElement>
    );
  };

  return (
    <Form
      initialValues={initialValue}
      key={JSON.stringify(initialValue)}
      onSubmit={(dataItem) => {
        setHasBeenPressed(true);
        setTimeout(() => {
          setHasBeenPressed(false);
        }, 1000);
        setAuthError("");

        newPasswordRequiredCallback(dataItem as NewPasswordFieldProps);
      }}
      render={form}
    />
  );
};
