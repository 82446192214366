import React, { useEffect, useState } from "react";
import "./gpsVisualizationContainer.css";
import { GpsChartContainer } from "./gpsChartContainer";
import { AppState } from "../../store/store";
import { cPhoneWidthLim } from "../../app";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../store/store";
import {
  gpsPointType,
  notePointType,
  objectPointType,
  objectTypes,
  translateFromSwe,
} from "../../store/plot/types";
import {
  setAllMeasurementNotes,
  setAllMeasurementObjects,
} from "../../store/plot/plotSlice";
import { computeResolution } from "./groupChart/chartConfig";
import { getMeasurementMetaData } from "../../api/netRail/measurements";
import * as plotAPI from "./../../api/netRail/plot";

interface VisualizationProps {
  selectedMeasurement: string;
}

export const GpsVisualization: React.FunctionComponent<VisualizationProps> = ({
  selectedMeasurement,
}) => {
  const screenWidth = useSelector(
    (state: AppState) => state.scheduler.screenWidth
  );
  const height = useSelector((state: AppState) => state.scheduler.screenHeight);

  const plans = useSelector((state: AppState) => state.scheduler.plans);

  const selectedPlan = useSelector(
    (state: AppState) => state.scheduler.selectedPlan
  );

  const plan = plans.find((plan) => plan.id == selectedPlan);

  const dispatch = useAppDispatch();

  const [finishedLoadingObjects, setFinishedLoadingObjects] =
    useState<boolean>(false);

  const [gpsPoints, setGpsPoints] = useState<gpsPointType[]>([]);

  async function getGpsData() {
    if (selectedMeasurement === "-1") {
      setGpsPoints([]);
      dispatch(setAllMeasurementObjects([]));
      dispatch(setAllMeasurementNotes([]));
      setFinishedLoadingObjects(true);
      return;
    }
    try {
      const metaData = await getMeasurementMetaData(
        "trackGauge",
        selectedMeasurement
      );

      if (!metaData) {
        setGpsPoints([]);
        dispatch(setAllMeasurementObjects([]));
        dispatch(setAllMeasurementNotes([]));
        setFinishedLoadingObjects(true);
        return;
      }

      const xMin = metaData.startKm * 1000 + metaData.startMeter;
      const xMax = metaData.endKm * 1000 + metaData.endMeter;
      const resolution = computeResolution(xMin, xMax, screenWidth);

      const pts = await plotAPI.getGpsData(
        selectedMeasurement,
        xMin,
        xMax,
        resolution,
        "gps",
        ["tick", "class"]
      );

      setGpsPoints(pts);

      const objects = await plotAPI.getGpsObjectData(
        selectedMeasurement,
        xMin,
        xMax,
        0.5,
        "gps",
        ["tick", "objects", "notes", "class"]
      );

      let allMeasurementObjects = objects
        .map((obj) => {
          if (obj.objects.length > 0 || obj.notes !== "") {
            return {
              x: obj.x,
              gpsX: obj.gpsX,
              gpsY: obj.gpsY,
              tick: obj.tick,
              type: obj.objects, // Need to differ the object type
              value: obj.objects[0],
              note: obj.notes,
            } as objectPointType;
          } else {
            // Dummy item to avoid bug
            return {
              x: 0,
              gpsX: 0,
              gpsY: 0,
              tick: obj.tick,
              type: [],
              value: "",
              note: "",
            } as objectPointType;
          }
        });

      // IF OBJECT IN DB IS IN SWEDISH, TRANSLATE IT TO ENG: (THIS IS PURE LEGACY SUPPORT)
      allMeasurementObjects = allMeasurementObjects.map((obj) => {
        if (!Object.values(objectTypes).includes(obj.value as objectTypes)) {
          return {
            ...obj,
            value: translateFromSwe(obj.value),
            type: obj.type.map((val) =>
              translateFromSwe(val)
            ) as objectTypes[],
          };
        } else {
          return obj;
        }
      });

      dispatch(setAllMeasurementObjects(allMeasurementObjects));

      const existingNotes = objects.filter((obj) => obj.notes !== "");
      const allMeasurementNotes = existingNotes.map((note) => ({
        x: note.x,
        gpsX: note.gpsX,
        gpsY: note.gpsY,
        tick: note.tick,
        type:
          note.notes === objectTypes.marker || note.notes === "Markering"
            ? objectTypes.marker
            : objectTypes.notes,
        value:
          note.notes === objectTypes.marker
            ? objectTypes.marker
            : note.notes,
      }));

      dispatch(setAllMeasurementNotes(allMeasurementNotes));

      setFinishedLoadingObjects(true);
    } catch (err) {
      console.log("Error in getGpsData:", err);
      dispatch(setAllMeasurementObjects([]));
      dispatch(setAllMeasurementNotes([]));
      setGpsPoints([]);
      setFinishedLoadingObjects(true);
    }
  }

  useEffect(() => {
    // Update gps trace:
    getGpsData();
  }, [selectedMeasurement]);

  return (
    <div
      className="GpsVisualizationContainer"
      style={{
        paddingLeft: screenWidth >= cPhoneWidthLim ? "40px" : "10px",
        paddingRight: screenWidth >= cPhoneWidthLim ? "40px" : "10px",
        paddingTop: selectedMeasurement !== "-1" ? "0px" : "60px",
      }}
    >
      <GpsChartContainer
        finishedLoadingObjects={finishedLoadingObjects}
        gpsData={gpsPoints}
        selectedMeasurement={selectedMeasurement}
      />
    </div>
  );
};