import { createSlice } from "@reduxjs/toolkit";
import { companyState } from "./types";
import { TFunction } from "i18next";
import rest from "../../../src/rest";
import { setNotification } from "../user/userSlice";
import { AppState } from "../store";
import { setAuthenticationSuccess } from "../user/userSlice";

const initialState: companyState = {
  currentCompany: undefined,
  allCompanies: [],
  selectedCompany: "",
  companyHasChanged: false,
};


export const saveCompanyToServer = (
  t: TFunction<"translation", "translation">,
  name: string,
  type: string,
  description: string,
  companyID?: string,
  sentToServer?: boolean
) => {
  return (dispatch: any, getState: () => AppState) => {
    if (!sentToServer) {
      rest
        .post(
          "/companies",
          {
            name: name,
            description: description,
            type: type,
          },
          { withCredentials: true }
        )
        .then((response) => {
          const companyID = response.data.id;
          if (companyID.length !== 0) {
            rest
              .get("/companies", { withCredentials: true })
              .then((response) => {
                const allFetchedCompanies = response.data.list.map(
                  (element: companyState) => {
                    return { ...element, sentToServer: true };
                  }
                );
                dispatch(setAllCompanies(allFetchedCompanies));
              })
              .catch((error) => {
                console.log(error);
                dispatch(setCompanyHasChanged(true));
              });
            dispatch(setSelectedCompany(companyID));
          }
          dispatch(setCompanyHasChanged(false));
          dispatch(setAuthenticationSuccess(true));
          dispatch(
            setNotification({
              style: "success",
              message: t("store.company.companyAdded"),
              open: true,
            })
          );
        })
        .catch((error) => {
          if (error.message === "Network Error") {
            alert(t("store.networkError"));
          } else if (error.message === "Request failed with status code 409") {
            dispatch(
              setNotification({
                style: "error",
                message: t("store.company.companyExists"),
                open: true,
              })
            );
          } else {
            dispatch(
              setNotification({
                style: "error",
                message: t("statusProgress.unexpectedError"),
                open: true,
              })
            )
          }
          dispatch(setCompanyHasChanged(true));
          dispatch(setAuthenticationSuccess(false));
        });
    } else if (sentToServer && companyID) {
      rest
        .patch(
          "/companies/" + companyID,
          {
            name: name,
            description: description,
            type: type,
          },
          { withCredentials: true }
        )
        .then((response) => {
          dispatch(
            updateCompanyProperty({
              id: companyID,
              name: name,
              description: description,
              type: type,
            })
          );
          dispatch(setCompanyHasChanged(false));
          dispatch(
            setNotification({
              style: "success",
              message: t("store.company.companyUpdated"),
              open: true,
            })
          );
        })
        .catch((error) => {
          dispatch(setCompanyHasChanged(true));
          if (error.message === "Network Error") {
            alert(t("store.networkError"));
          } else {
            dispatch(
              setNotification({
                style: "warning",
                message: t("store.company.companyExists"),
                open: true,
              })
            );
          }
          console.log("error", error);
        });
    }
  };
};



export const removeCompany = (
    t: TFunction<"translation", "translation">,
    companyID: string
  ) => {
    return (dispatch: any) => {
      rest
        .delete("/companies/" + companyID, { withCredentials: true })
        .then((response) => {
          dispatch(deleteCompany(companyID));
  
          dispatch(
            setNotification({
              style: "success",
              message: t("store.company.deletedCompany"),
              open: true,
            })
          );
        })
        .catch(() => {
          console.error("Failed to delete company with companyID ", companyID);
          dispatch(
            setNotification({
              style: "error",
              message: t("store.company.deletedCompanyError"),
              open: true,
            })
          );
        });
    };
  };  

export const companySlice = createSlice({
    name: "company",
    initialState,
    reducers: {
        setAllCompanies: (state, action) => {
            state.allCompanies = action.payload.map((company: any) => {
                return { ...company, sentToServer: true };
            });
            state.selectedCompany = "";
            state.companyHasChanged = false;
        },
        deleteCompany: (state, action) => {
            state.allCompanies = state.allCompanies.filter(
                (company) => company.id !== action.payload
            );
            state.selectedCompany = "";
        },
        setSelectedCompany: (state, action) => {
            state.selectedCompany = action.payload;
        },
        addNewCompany: (state, action) => {
            state.allCompanies.push({ ...action.payload });
            state.selectedCompany = action.payload.id;
            state.companyHasChanged = true;
        },
        updateCompanyProperty: (state, action) => {
            state.allCompanies = state.allCompanies.map((company) => {
                return company.id === state.selectedCompany
                    ? { ...company, ...action.payload }
                    : company;
            });
        },
        setCompanyHasChanged: (state, action) => {
            state.companyHasChanged = action.payload;
        },
        setCurrentCompany: (state, action) => {
            state.currentCompany = action.payload;
        },
    },
});

export const {
    setAllCompanies,
    deleteCompany,
    setSelectedCompany,
    addNewCompany,
    updateCompanyProperty,
    setCompanyHasChanged,
    setCurrentCompany,
} = companySlice.actions;

export default companySlice.reducer;