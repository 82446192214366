import React, { useRef, useEffect, useState } from "react";
import "./planList.css";
import PlanListHeader from "./planListHeader";
import PlanListItem from "./planListItem";

/*Redux stuff */
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../store/store";
import { Scrollbars } from "react-custom-scrollbars-2";
import { AppState } from "../../../store/store";
import {
  planProps,
  planStatus,
  requestMetaData,
} from "../../../store/scheduler/types";
import { customThumb } from "../../manageUsers/userList";
import * as plansAPI from "./../../../api/netRail/plans";
import { setAllPlans } from "../../../store/scheduler/schedulerSlice";
import { useTranslation } from "react-i18next";
import { Spinner } from "../../spinner";
import { copyFileSync } from "fs";

interface PlanListProps {
  onlySelectPlan?: boolean;
}

const offset = 0;
const limits = [10, 25, 50, 75, 100, 500];

const PlanList: React.FC<PlanListProps> = ({ onlySelectPlan }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const applicationState = useSelector((state: AppState) => state.scheduler);
  const [plansMetaData, setPlansMetaData] = useState<requestMetaData>();
  const [visiblePlans, setVisiblePlans] = useState<planProps[]>();
  const [filterOperator, setFilterOperator] = useState<string>(
    t("common.operators").toString()
  );
  const [filterMachine, setFilterMachine] = useState<string>(
    t("common.machines").toString()
  );
  const [filterStatus, setFilterStatus] = useState<planStatus>();
  const [textFilterName, setTextFilterName] = useState<string>("");

  const allCompanyUsers = useSelector(
    (state: AppState) => state.user.allCompanyUsers
  );
  const selectedLanguage = useSelector(
    (state: AppState) => state.user.selectedLanguage
  );
  const allMachines = useSelector(
    (state: AppState) => state.machine.allMachines
  );

  // const page = useRef(1);
  const [page, setPage] = useState(1);
  const limit = useRef(25);

  const getPlans = async (page: number) => {
    try {
      const selectedUserID = allCompanyUsers.find(
        (user) => user.email === filterOperator
      )?.id;

      const selectedMachineID = allMachines.find(
        (machine) => machine.name === filterMachine
      )?.id;

      const [resp, metaData] = await plansAPI.getPlans(
        textFilterName,
        filterStatus ? filterStatus : undefined,
        filterOperator !== t("common.operators").toString()
          ? selectedUserID
          : undefined,
        filterMachine !== t("common.machines").toString()
          ? selectedMachineID
          : undefined,
        applicationState.planSorterType,
        limit.current,
        page,
        offset
      );
      setVisiblePlans(resp);
      dispatch(setAllPlans(resp));
      setPlansMetaData(metaData);
    } catch {
      console.log("failed request");
    }
  };

  useEffect(() => {
    getPlans(page);
  }, [
    textFilterName,
    filterOperator,
    filterMachine,
    filterStatus,
    applicationState.planSorterType,
    page,
  ]);

  useEffect(() => {
    setFilterMachine(t("common.machines").toString());
    setFilterOperator(t("common.operators").toString());
  }, [selectedLanguage]);

  //Clear the item size array cache if we change the visiblePlan
  const listRef = useRef<any>(null);
  useEffect(() => {
    if (listRef.current !== null) {
      listRef.current.resetAfterIndex(0, true);
    }
  });

  // ****
  // HANDLE SCROLLING IF WE HAVE UNEVENLY SCALED PAGES. THIS GIVES A MORE CONSISTENT EXPERIENCE
  // const el = useRef<any>(null);
  // const scrollToBottom = (el: any) => {
  //   el.current?.scrollIntoView({
  //     behavior: "smooth",
  //     block: "nearest",
  //   });
  // };

  // useEffect(() => {
  //   scrollToBottom();
  // }, [scrollComponent]);
  // ****

  const Row = (plan: planProps) => {
    return (
      <div className="PlanListItemWrapper" key={plan.id}>
        <PlanListItem plan={plan} onlySelectPlan={onlySelectPlan} />
      </div>
    );
  };

  const footer = (plansMetaData: requestMetaData) => {
    let itemStart = 1;

    const options = Array(Math.ceil(plansMetaData.total / plansMetaData.limit))
      .fill(0)
      .map(() => {
        const item = itemStart;
        itemStart += 1;
        return t("common.page") + " " + item;
      });

    return (
      <div className="PlanListFooterContainer">
        <div className="PlanListFooterPages">
          <button
            className="PlanListFooterPagesIcon BUTTON"
            style={{
              opacity: page <= 1 ? 0.2 : 1,
            }}
            disabled={page <= 1}
            onClick={async () => {
              setPage((page) => page - 1);
              // scrollToBottom(el);
            }}
          >
            <span
              style={{ height: "24px", width: "24px", fontSize: "24px" }}
              className="k-icon k-font-icon k-i-arrow-chevron-left"
            />
          </button>
          <div className="PlanListFooterSelectPages">
            <select
              className="PlanListFooterSelect"
              name=""
              id=""
              onChange={async (e) => {
                setPage(+e.target.value.split(t("common.page").toString())[1]);
              }}
              value={t("common.page") + " " + page.toString()}
            >
              {options.map((opt) => {
                return (
                  <option
                    className="PlanListFooterOption"
                    value={opt}
                    key={`PlanListFooterOption${opt}`}
                  >
                    {opt}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="PlanListFooterSelectPages">
            <select
              className="PlanListFooterSelect"
              name=""
              id=""
              value={limit.current}
              onChange={async (e) => {
                limit.current = +e.target.value;
                await getPlans(page);
                // scrollToBottom(el);
              }}
            >
              {limits.map((lim) => {
                return (
                  <option
                    className="PlanListFooterOption"
                    value={lim}
                    key={`PlanListFooterOption${lim}`}
                  >
                    {lim} /{t("common.page")}
                  </option>
                );
              })}
            </select>
          </div>
          <button
            className="PlanListFooterPagesIcon BUTTON"
            style={{
              opacity: limit.current * page >= plansMetaData.total ? 0.2 : 1,
            }}
            disabled={limit.current * page >= plansMetaData.total}
            onClick={async () => {
              setPage((p) => p + 1);
              // scrollToBottom(el);
            }}
          >
            <span
              style={{ height: "24px", width: "24px", fontSize: "24px" }}
              className="k-icon k-font-icon k-i-arrow-chevron-right"
            />
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className="PlanList">
      <PlanListHeader
        textFilterName={textFilterName}
        filterOperator={filterOperator}
        filterMachine={filterMachine}
        setTextFilterName={setTextFilterName}
        setFilterOperator={setFilterOperator}
        setFilterMachine={setFilterMachine}
        setFilterStatus={setFilterStatus}
        filterStatus={filterStatus}
        limit={limit}
        setPage={setPage}
        onlySelectPlan={onlySelectPlan}
      />

      <Scrollbars
        thumbSize={60}
        renderThumbVertical={customThumb}
        autoHide={true}
        autoHideTimeout={1000}
        autoHideDuration={500}
        style={{
          overflowX: "hidden",
        }}
      >
        <div className={"PlanListWrapper"}>
          {visiblePlans ? visiblePlans.map((plan) => Row(plan)) : <Spinner />}
        </div>
        {plansMetaData ? footer(plansMetaData) : null}
      </Scrollbars>
    </div>
  );
};

export default PlanList;
