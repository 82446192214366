import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { Input } from "@progress/kendo-react-inputs";
import { confirmResetPassword } from "aws-amplify/auth";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Timer, { SignInSteps, errorCodes } from "../../pages/login/page";
import { CustomInputUser } from "../manageUsers/updateUserForm";
import {
  emailValidator,
  newPasswordValidator,
  notEmptyValidator,
} from "../../helpers/validators";

interface ForgotPasswordSubmitFormProps {
  handleError: (
    err: any,
    setAuthError: React.Dispatch<React.SetStateAction<string>>,
    code?: number
  ) => void;
  setNextStep: React.Dispatch<React.SetStateAction<SignInSteps | undefined>>;
  forgotPasswordEmail: React.MutableRefObject<string | undefined>;
}

interface ForgotPasswordSubmitFieldProps {
  email: string;
  code: string;
  newPassword: string;
}

export const ForgotPasswordSubmitForm: React.FunctionComponent<
  ForgotPasswordSubmitFormProps
> = ({ handleError, setNextStep, forgotPasswordEmail }) => {
  const { t } = useTranslation();
  const [hasBeenPressed, setHasBeenPressed] = useState(false);
  const [authError, setAuthError] = useState("");
  const [viewNewPassword, setViewNewPassword] = useState(false);
  const [isTimerDone, setIsTimerDone] = useState(false); // TODO: Timer is not functioning here.
  const initialValue = { email: forgotPasswordEmail.current };

  const handleForgotPasswordSubmit = useCallback(
    async (dataItem: ForgotPasswordSubmitFieldProps) => {
      try {
        await confirmResetPassword({
          username: dataItem.email,
          confirmationCode: dataItem.code,
          newPassword: dataItem.newPassword,
        });

        setNextStep(undefined);
      } catch (err) {
        console.log(err);
        handleError(err, setAuthError, errorCodes.forgotPWSubmitError);
      }
    },
    [handleError]
  );

  const form = (formRenderProps: FormRenderProps) => {
    const { valid, valueGetter } = formRenderProps;

    const passwordMatch =
      valueGetter("newPassword") === valueGetter("repeatPassword");
    return (
      <FormElement className="LoginForm">
        <Field
          name={"email"}
          component={Input}
          type={"email"}
          label={t("loginPage.email")}
          validator={(v) => emailValidator(t, v)}
          disabled={true}
        />
        <Field
          name={"code"}
          component={CustomInputUser}
          type={"code"}
          label={t("loginPage.authenticationCode")}
          validator={(v) => notEmptyValidator(t, v)}
        />
        <Timer
          initialMinute={2}
          isTimerDone={isTimerDone}
          setIsTimerDone={setIsTimerDone}
          setNextStep={setNextStep}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            position: "relative",
          }}
        >
          <Field
            name={"newPassword"}
            component={CustomInputUser}
            type={viewNewPassword ? "text" : "password"}
            label={t("loginPage.newPassword")}
            validator={(v) => newPasswordValidator(t, v)}
          />
          <span
            className="k-icon k-font-icon  k-font-icon k-i-preview"
            style={
              viewNewPassword
                ? {
                    fontSize: "16px",
                    backgroundColor: "var(--primary-color)",
                    color: "white",
                    borderRadius: "6px",
                    right: "0",
                    position: "absolute",
                    top: "26px",
                  }
                : {
                    fontSize: "16px",
                    borderRadius: "6px",
                    right: "0",
                    position: "absolute",
                    color: "var(--primary-color)",
                    top: "26px",
                  }
            }
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setViewNewPassword(!viewNewPassword);
            }}
            onMouseLeave={() => {
              setViewNewPassword(false);
            }}
          />
        </div>
        <Field
          name={"repeatPassword"}
          component={Input}
          type={"password"}
          label={t("loginPage.repeatPassword")}
          valid={passwordMatch}
        />
        {!passwordMatch ? (
          <div
            style={{
              color: "red",
              height: "11px",
              fontSize: "10px",
            }}
          >
            {t("loginPage.passwordDontMatch")}
          </div>
        ) : (
          <div style={{ height: "11px" }} />
        )}
        <div style={{ color: "red" }}>
          {authError && authError.length > 0 ? authError : ""}{" "}
        </div>
        <div className="k-form-buttons">
          <button
            className="LoginResendMFAButton"
            type="button"
            onClick={(e) => {
              e.preventDefault();
              setNextStep("SHOW_FORGOT_PASSWORD_CONTENT");
            }}
          >
            {t("loginPage.noAuthenticationCode")}
          </button>
        </div>
        <div className="k-form-buttons">
          <button
            type={"submit"}
            className="LoginButton BUTTON"
            disabled={
              !formRenderProps.allowSubmit ||
              hasBeenPressed ||
              !valid ||
              !passwordMatch
            }
            style={
              !formRenderProps.allowSubmit ||
              hasBeenPressed ||
              !valid ||
              !passwordMatch
                ? { opacity: 0.5 }
                : {}
            }
          >
            {t("loginPage.update")}
          </button>
        </div>
      </FormElement>
    );
  };

  return (
    <Form
      initialValues={initialValue}
      key={JSON.stringify(initialValue)}
      onSubmit={(dataItem) => {
        setHasBeenPressed(true);
        setTimeout(() => {
          setHasBeenPressed(false);
        }, 1000);
        setAuthError("");
        handleForgotPasswordSubmit(dataItem as ForgotPasswordSubmitFieldProps);
      }}
      render={form}
    />
  );
};
