import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { Input } from "@progress/kendo-react-inputs";
import {
  confirmSignIn,
  rememberDevice,
  sendUserAttributeVerificationCode,
  updateMFAPreference,
} from "aws-amplify/auth";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { onLogin } from "../../store/user/userSlice";
import { useAppDispatch } from "../../store/store";
import Timer, { SignInSteps, errorCodes } from "../../pages/login/page";
import { notEmptyValidator } from "../../helpers/validators";

interface MfaRequiredFormProps {
  handleError: (
    err: any,
    setAuthError: React.Dispatch<React.SetStateAction<string>>,
    code?: number
  ) => void;
  setNextStep: React.Dispatch<React.SetStateAction<SignInSteps | undefined>>;
}

interface MfaRequiredFieldProps {
  mfaCode: string;
}

export const MfaRequiredForm: React.FunctionComponent<MfaRequiredFormProps> = ({
  handleError,
  setNextStep,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [hasBeenPressed, setHasBeenPressed] = useState(false);
  const [authError, setAuthError] = useState("");
  const [verifyPhoneNumberRequired, setVerifyPhoneNumberRequired] =
    useState(false);
  const [isTimerDone, setIsTimerDone] = useState<boolean>(false);
  const initialValue = undefined;

  const mfaRequiredCallback = useCallback(
    async (dataItem: MfaRequiredFieldProps) => {
      // TODO fix any

      try {
        const smsMFA = dataItem.mfaCode; // code retrieved from authenticator App or cellphone
        const { isSignedIn, nextStep } = await confirmSignIn({
          challengeResponse: smsMFA,
        });
        setNextStep(nextStep.signInStep);

        // On Login we want to record the preferred MFA
        try {
          const resp = await updateMFAPreference({
            sms: "ENABLED",
            totp: "DISABLED",
          });
        } catch (err) {
          handleError(err, setAuthError, errorCodes.setPrefferedMFAError);
        }

        // Remember the device we logged in with
        try {
          await rememberDevice();
        } catch (error) {
          console.log("Error occurred when remembering device: ", error);
        }

        dispatch(onLogin({t}));
      } catch (err) {
        handleError(err, setAuthError, errorCodes.confirmMFAError);
      }
    },
    [handleError]
  );

  const form = (formRenderProps: FormRenderProps) => {
    return (
      <FormElement className="LoginForm">
        <Field
          name={"mfaCode"}
          component={Input} //TextInput}
          label={t("loginPage.authenticationCode")}
          validator={(v) => notEmptyValidator(t, v)}
        />
        <div style={{ color: "red" }}>
          {authError && authError.length > 0 ? authError : ""}{" "}
        </div>
        <Timer
          initialMinute={2}
          isTimerDone={isTimerDone}
          setIsTimerDone={setIsTimerDone}
          setNextStep={setNextStep}
        />
        <div className="k-form-buttons">
          <button
            type={"submit"}
            className="LoginButton BUTTON"
            disabled={!formRenderProps.allowSubmit || hasBeenPressed}
            style={
              !formRenderProps.allowSubmit || hasBeenPressed
                ? { opacity: 0.5 }
                : {}
            }
          >
            {t("common.ok")}
          </button>
        </div>
      </FormElement>
    );
  };

  return (
    <Form
      initialValues={initialValue}
      key={JSON.stringify(initialValue)}
      onSubmit={(dataItem) => {
        setHasBeenPressed(true);
        setTimeout(() => {
          setHasBeenPressed(false);
        }, 1000);
        setAuthError("");

        mfaRequiredCallback(dataItem as MfaRequiredFieldProps);
      }}
      render={form}
    />
  );
};
