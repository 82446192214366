import React, { useEffect, useState } from "react";
import { Navigate, Route, Routes, useNavigate, useLocation } from "react-router-dom";
import { AppState, useAppDispatch } from "./store/store";
import { useSelector } from "react-redux";
import { setSelectedMeasurement, setSelectedPlan } from "./store/scheduler/schedulerSlice";
import { logout, onLogin } from "./store/user/userSlice";
import { Menu } from "./components/menu";
import { fetchAuthSession } from "aws-amplify/auth";
import { getLastVisitedPlanAndMeasurement, setLastVisitedPlanAndMeasurement } from "./helpers/genericHelpers";
import { useTranslation } from "react-i18next";
import { fetchStoredAnnouncement } from "./components/announcement/announcement";
import * as announcementsAPI from "./api/netRail/announcements";

interface ProtectedRouteProps {
  children: JSX.Element;
}

export const ProtectedRoute: React.FunctionComponent<ProtectedRouteProps> = ({ children }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [userIsLoggedIn, setUserIsLoggedIn] = useState(false);
  const currentUser = useSelector((state: AppState) => state.user.currentUser);
  const isAuthenticated = useSelector((state: AppState) => state.user.authenticationSuccess);

  if (!isAuthenticated) {
    return <Navigate to="/login" />
  }

  const Today = new Date();

  useEffect(() => {
    const checkAuthenticatedUser = async () => {
      try {
        const currentUser = await fetchAuthSession();

        if (!currentUser) {
          dispatch(onLogin({ t }));
          const lastVisitedPage = getLastVisitedPlanAndMeasurement();
          if (lastVisitedPage?.lastPlan) {
            dispatch(setSelectedPlan(lastVisitedPage.lastPlan));
          }
          if (lastVisitedPage?.lastMeasurement) {
            dispatch(setSelectedMeasurement(lastVisitedPage.lastMeasurement));
          } else {
            setSelectedMeasurement("");
          }
        }
        setUserIsLoggedIn(true);
      } catch (err) {
        navigate("/login", { replace: true });
        console.log("Not authenticated", err);
      }
    };

    checkAuthenticatedUser();
  }, [currentUser, dispatch, navigate, t]);

  const allPlans = useSelector((state: AppState) => state.scheduler.plans);

  const getAPIAnnouncementsLocally = async () => {
    try {
      const announcements = await announcementsAPI.getAnnouncements();
      const storedAnnouncements = fetchStoredAnnouncement();
      if (storedAnnouncements.length > 0) {
        const filteredStoredAnnouncementList = announcements.filter(
          (announcement) => {
            return (
              storedAnnouncements.includes(announcement.id) &&
              new Date(announcement.expiresAt) > Today
            );
          }
        );

        const filteredStoredAnnouncementIDList = filteredStoredAnnouncementList.map(
          (announcement) => announcement.id
        );

        localStorage.setItem(
          "storedAnnouncements",
          JSON.stringify(filteredStoredAnnouncementIDList)
        );
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    if (currentUser) {
      setUserIsLoggedIn(true);
      getAPIAnnouncementsLocally();
      const lastVisitedResources = getLastVisitedPlanAndMeasurement();

      if (
        lastVisitedResources?.lastPlan &&
        allPlans.some((plan) => plan.id === lastVisitedResources.lastPlan)
      ) {
        dispatch(setSelectedPlan(lastVisitedResources.lastPlan));
      } else if (lastVisitedResources?.lastPlan && allPlans.length > 0) {
        navigate("/scheduler");
        setLastVisitedPlanAndMeasurement(undefined, undefined);
      }

      if (lastVisitedResources?.lastMeasurement) {
        dispatch(setSelectedMeasurement(lastVisitedResources.lastMeasurement));
      }
    }
  }, [currentUser, allPlans.length, dispatch, navigate]);

  if (!userIsLoggedIn) {
    return null;
  }

  if (
    (location.pathname.includes("announcements") || location.pathname.includes("manageCompanies")) &&
    currentUser?.roles.some((role) => role.name !== "ADMIN")
  ) {
    return <Navigate to="/scheduler" replace />;
  }

  return (
    <>
      {currentUser ? <Menu /> : null}
      {children}
    </>
  );
};