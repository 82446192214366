import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { AppState } from '../store';
import {
    schedulerState,
    planSubpages,
    parametersProps,
    planProps,
    planStatus,
} from "./types";
import { getNewPlan } from "../../development/initializer";
import rest from "../../../src/rest";
import { setNotification } from "../user/userSlice";
import axios from "axios";
import { setLastVisitedPlanAndMeasurement } from "../../helpers/genericHelpers";
import { TFunction } from "i18next";
import { current } from "@reduxjs/toolkit";

const initialState: schedulerState = {
    plans: [],
    totalPlansOnServer: 0,
    planSorterType: "-createdAt",
    selectedPlan: "-1",
    selectedSubpage: planSubpages.plan,
    planHasChanged: false,
    selectedMeasurement: "-1",
    expandMenu: false,
    externalAccessToken: "",
    screenWidth: window.innerWidth,
    screenHeight: window.innerHeight,
    newPlanSelected: false,
};

export const addNewPlan = createAsyncThunk(
    'scheduler/addNewPlan',
    async ({ prefilledPlan }: { prefilledPlan?: planProps }, { dispatch, getState }) => {
        const { user } = getState() as AppState;
        const userID = user.currentUser ? user.currentUser.id : "";
        if (prefilledPlan) {
            const prefilledPlanID = getNewPlan(1, userID).id;
            const newPlan = {
                ...prefilledPlan,
                id: prefilledPlanID,
                sentToServer: false,
                measurements: [],
                status: planStatus.awaiting,
                scheduledAt: new Date().toISOString(),
                createdAt: new Date().toISOString(),
                doneAt: new Date().toISOString(),
                updatedAt: new Date().toISOString(),
            };
            dispatch(addNewReduxPlan(newPlan));
            dispatch(setSelectedPlan(prefilledPlanID));
            setLastVisitedPlanAndMeasurement(prefilledPlanID, undefined);
            dispatch(setNewPlanSelected(true));
        } else {
            const plan = getNewPlan(1, userID);
            dispatch(addNewReduxPlan(plan));
            dispatch(setSelectedPlan(plan.id));
            setLastVisitedPlanAndMeasurement(plan.id, undefined);
            dispatch(setNewPlanSelected(true));
        }
    }
);

export const removeMeasurement = createAsyncThunk(
    'scheduler/removeMeasurement',
    async (measurementID: string, { dispatch }) => {
        try {
            await rest.delete(`/measurements/${measurementID}`, { withCredentials: true });
            dispatch(deleteMeasurement(measurementID));
        } catch (error) {
            console.error("Failed to delete measurement with measurementID ", measurementID);
            dispatch(setNotification({
                style: "error",
                message: "store.plan.deleteMeasurementError",
                open: true,
            }));
        }
    }
);


export const uploadProjectedTrack = async (file: File, planID: string) => {
    const fsize = file.size;
    const fileSize = Math.round(fsize / 1024);

    const postPresigned = {
        name: file.name,
        type: "projected_track",
        size: fileSize,
        ownerType: "plan",
        ownerID: planID,
        partType: "complete",
        part: 0,
    };
    const fileReader = new FileReader();
    const binaryFile = fileReader.readAsBinaryString(file);
    try {
        const response = await rest.post("/files/uploadURL", postPresigned);
        const presignedURL: string = response.data.presignedURL;
        const path = response.data.fileKey;
        await axios.put(
            presignedURL,
            {
                binaryFile,
            },
            {
                headers: {
                    "X-Amz-meta-name": postPresigned.name.toString(),
                    "X-Amz-meta-part": postPresigned.part.toString(),
                    "X-Amz-meta-part_type": postPresigned.partType.toString(),
                    "X-Amz-meta-type": postPresigned.type.toString(),
                    "X-Amz-meta-size": postPresigned.size.toString(),
                    "X-Amz-meta-path": path.toString(),
                    "X-Amz-meta-owner_id": postPresigned.ownerID.toString(),
                    "X-Amz-meta-owner_type": postPresigned.ownerType.toString(),
                },
            }
        );
    } catch (error) {
        console.log("uploadURL error", error);
    }
}

export const savePlanToServer = createAsyncThunk(
    'scheduler/savePlanToServer',
    async ({ t, plan }: { t: TFunction<"translation", "translation">, plan: planProps }, { dispatch, getState }) => {
        if (plan.sentToServer) {
            const { id, creatorID, createdAt, measurements, updatedAt, sentToServer, doneAt, files, projectedTrackFile, ...planToPatch } = plan;
            try {
                await rest.patch(`/plans/${plan.id}`, planToPatch, { withCredentials: true });
                if (plan.projectedTrackFile) {
                    await uploadProjectedTrack(plan.projectedTrackFile, plan.id);
                }
                dispatch(setPlanHasChanged(false));
                dispatch(updatePlanProperty(planToPatch));
                dispatch(setNotification({
                    style: "success",
                    message: t("store.plan.planUpdated"),
                    open: true,
                }));
                setLastVisitedPlanAndMeasurement(plan.id, undefined);
            } catch (error) {
                console.error("fail to upload plan: ", error);
                dispatch(setNotification({
                    style: "error",
                    message: t("store.plan.savePlanError"),
                    open: true,
                }));
            }
        } else {
            const { id, creatorID, createdAt, measurements, updatedAt, doneAt, files, sentToServer, projectedTrackFile, ...planToPost } = plan;
            try {
                const fulfill = await rest.post("/plans", planToPost, { withCredentials: true });
                const planResponse = fulfill.data;
                dispatch(updatePlanProperty({
                    id: planResponse.id,
                    sentToServer: true,
                    creatorID: planResponse.creatorID,
                    createdAt: planResponse.createdAt,
                    updatedAt: planResponse.updatedAt,
                }));
                dispatch(setSelectedPlan(planResponse.id));
                if (plan.projectedTrackFile) {
                    await uploadProjectedTrack(plan.projectedTrackFile, planResponse.id);
                }
                dispatch(setPlanHasChanged(false));
                dispatch(setNotification({
                    style: "success",
                    message: t("store.plan.planSaved"),
                    open: true,
                }));
                setLastVisitedPlanAndMeasurement(planResponse.id, undefined);
            } catch (error) {
                console.error("fail to upload plan: ", error);
                dispatch(setNotification({
                    style: "error",
                    message: t("store.plan.savePlanError"),
                    open: true,
                }));
            }
        }
    }
);

export const schedulerSlice = createSlice({
    name: "scheduler",
    initialState,
    reducers: {
        setPlanSorterType: (state, action) => {
            state.planSorterType = action.payload;
        },
        
        addNewReduxPlan: (state, action: PayloadAction<planProps>) => {
            state.plans.push({ ...action.payload });
            state.selectedPlan = action.payload.id;
            state.planHasChanged = true;
        },

        setAllPlans: (state, action: PayloadAction<planProps[]>) => {
            state.plans = action.payload.map((plan) => {
                return { ...plan, sentToServer: true };
            });
            state.planHasChanged = false;
            state.totalPlansOnServer = action.payload.length;
        },

        setSelectedPlan: (state, action: PayloadAction<string>) => {
            state.selectedPlan = action.payload;
        },

        setSelectedSubPage: (state, action: PayloadAction<planSubpages>) => {
            state.selectedSubpage = action.payload;
        },

        // TODO:: BAD
        updatePlanParameter: (state, action: PayloadAction<parametersProps>) => {
            state.plans = state.plans.map((plan) => {
                return plan.id === state.selectedPlan
                    ? { ...plan, parameters: { ...plan, ...action.payload } }
                    : plan;
            });
        },

        updatePlanProperty: (state, action: PayloadAction<any>) => {
            state.plans = state.plans.map((plan) => {
                return plan.id === state.selectedPlan
                    ? { ...plan, ...action.payload }
                    : plan;
            });
        },

        updateMeasurementProperty: (state, action: PayloadAction<any>) => {
            const selectedPlan = state.plans.find(plan => plan.id === state.selectedPlan);
            if (selectedPlan) {
                selectedPlan.measurements = selectedPlan.measurements.map(meas =>
                    meas.id === state.selectedMeasurement ? { ...meas, ...action.payload } : meas
                );
            }
        },

        updateMeasurementStatus: (state, action: PayloadAction<{ id: string; properties: any }>) => {
            const selectedPlan = state.plans.find(plan => plan.id === state.selectedPlan);
            if (selectedPlan) {
                selectedPlan.measurements = selectedPlan.measurements.map(meas =>
                    meas.id === action.payload.id ? { ...meas, ...action.payload.properties } : meas
                );
            }
        },

        setPlanHasChanged: (state, action: PayloadAction<boolean>) => {
            state.planHasChanged = action.payload;
        },

        deletePlan: (state, action: PayloadAction<any>) => {
            state.plans = state.plans.filter((plan) => plan.id !== action.payload);
            state.totalPlansOnServer -= 1;
        },

        setTotalPlansOnServer: (state, action: PayloadAction<number>) => {
            state.totalPlansOnServer = action.payload;
        },

        deleteMeasurement: (state, action: PayloadAction<any>) => {
            state.plans = state.plans.map((plan) => {
                return {
                    ...plan,
                    measurements: plan.measurements.filter(
                        (measurement) => measurement.id !== action.payload
                    ),
                };
            });
        },

        setReduxProjectedTrack: (state, action: PayloadAction<any>) => {
            state.plans = state.plans.map((plan) => {
                return plan.id === action.payload.planID
                    ? { ...plan, projectedTrackFile: action.payload.file }
                    : plan;
            });
        },

        setAllMeasurements: (state, action: PayloadAction<any>) => { // NOT IN USE?
            return {
                ...state,
                plans: state.plans.map((plan) => {
                    return plan.id === action.payload.plan.id
                        ? { ...plan, measurements: [...action.payload.measurements] }
                        : plan;
                }),
            };
        },

        addOperatorToMeasurement: (state, action: PayloadAction<string>) => {
            return {
                ...state,
                plans: state.plans.map((plan) => {
                    return {
                        ...plan,
                        measurements: plan.measurements.map((measurement) => {
                            return {
                                ...measurement,
                                operatorID: action.payload,
                            };
                        }),
                    };
                }),
            };
        },

        setSelectedMeasurement: (state, action: PayloadAction<string>) => {
            state.selectedMeasurement = action.payload;
        },

        setExpandedMenu: (state, action: PayloadAction<boolean>) => {
            state.expandMenu = action.payload;
        },

        setExternalAccessToken: (state, action: PayloadAction<string>) => {
            state.externalAccessToken = action.payload;
        },

        setScreenWidth: (state, action: PayloadAction<number>) => {
            state.screenWidth = action.payload;
        },

        setScreenHeight: (state, action: PayloadAction<number>) => {
            state.screenHeight = action.payload;
        },

        setNewPlanSelected: (state, action: PayloadAction<boolean>) => {
            state.newPlanSelected = action.payload;
        }

    }
});

export const {
    setPlanSorterType,
    addNewReduxPlan,
    setAllPlans,
    deleteMeasurement,
    setSelectedPlan,
    setSelectedSubPage,
    updatePlanParameter,
    updatePlanProperty,
    updateMeasurementProperty,
    updateMeasurementStatus,
    setPlanHasChanged,
    setTotalPlansOnServer,
    setAllMeasurements,
    addOperatorToMeasurement,
    setExpandedMenu,
    setExternalAccessToken,
    setScreenWidth,
    setScreenHeight,
    setNewPlanSelected,
    setSelectedMeasurement,
} = schedulerSlice.actions;

export default schedulerSlice.reducer;