import React, { useEffect, useState } from "react";
import * as supportTicketAPI from "../../api/netRail/support";
import { helpInformationPath, supportTicketMessageType } from "../../store/user/types";
import { SupportCard } from "../../components/support/supportCard";
import { Toolbar } from "@progress/kendo-react-buttons";
import { t } from "i18next";
import Scrollbars from "react-custom-scrollbars-2";
import { useSelector } from "react-redux";
import { cPhoneWidthLim } from "../../app";
import { customThumb } from "../../components/manageUsers/userList";
import { AppState } from "../../store/store";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { filter } from "@progress/kendo-data-query/dist/npm/transducers";
import "./style.css"

const ListViewIcon: React.FC<any> = ({ color }) => (
  <svg viewBox="0 0 512 512" fill={color}>
    <path d="M448 160H64v-32h384zm0-96H64v32h384zM64 352h384v-32H64zm0-64h384v-32H64zm0-64h384v-32H64zm0 192h384v-32H64z"></path>
  </svg>
);

const CardViewIcon: React.FC<any> = ({ color }) => (
  <svg viewBox="0 0 512 512" fill={color}>
    <path d="M32 384h416v96H32zm0-32h416v-96H32zm0-128h416v-96H32zM32 0v96h416V0z"></path>
  </svg>
);

const ListToggle: React.FC<any> = ({ listView, setListView }) => {

  const ToggleIcon: React.FC<any> = ({ selected, onClick, IconComponent }) => {
    const color = selected ? "rgb(83, 209, 171)" : 'gray';
    return (
      <div
        onClick={onClick}
        className={`icon-wrapper ${selected ? 'selected' : ''}`}
        style={{
          width: '40px',
          height: '40px',
        }}
      >
        <IconComponent color={color} />
      </div>
    );
  };

  return (
    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around"}}>
      <ToggleIcon
        selected={!listView}
        onClick={() => setListView(false)}
        IconComponent={CardViewIcon}
      />
      <ToggleIcon
        selected={listView}
        onClick={() => setListView(true)}
        IconComponent={ListViewIcon}
      />
    </div>
  );
};

export const Support: React.FunctionComponent = () => {
  const [supportTicketList, setSupportTicketList] = useState<supportTicketMessageType[]>([]);
  // const [listView, setListView] = useState<boolean>(true);
  const listView = true;
  const [selectedTab, setSelectedTab] = useState<number>(0);

  const width = useSelector((state: AppState) => state.scheduler.screenWidth);
  const height = useSelector((state: AppState) => state.scheduler.screenHeight);
  const informationRequest = useSelector((state: AppState) => state.user.informationRequest);

  async function getAPISupportTicketsLocally() {
    try {
      const supportTickets = await supportTicketAPI.getSupportTickets();
      setSupportTicketList(supportTickets);
    } catch (err) {
      console.log("err", err);
    }
  }

  useEffect(() => {
    getAPISupportTicketsLocally();
  }, []);
  
  const updateSupportTicket = (supportTicket: supportTicketMessageType) => {
    const newSupportTicketList = supportTicketList.map((ticket) => {
      if (ticket.id === supportTicket.id) {
        return supportTicket;
      }
      return ticket;
    });
    setSupportTicketList(newSupportTicketList);
  };

  const removeSupportTicket = (id: string) => {
    const newSupportTicketList = supportTicketList.filter((ticket) => ticket.id !== id);
    setSupportTicketList(newSupportTicketList);
  };

  const handleTabSelect = (e: any) => {
    setSelectedTab(e.selected);
  };

  const statusOptions = [
    "Created",
    "Open",
    "Closed",
    "In Progress",
    "On Hold",
  ];
  
  const filteredTickets = supportTicketList.filter(ticket => {
    switch (selectedTab) {
      case 0: return ticket.status === statusOptions[0]; // "Created"
      case 1: return ticket.status === statusOptions[1] || ticket.status === statusOptions[4]; // "Open" or "On Hold"
      case 2: return ticket.status === statusOptions[3]; // "In Progress"
      case 3: return ticket.status === statusOptions[2]; // "Closed"
      default: return true;
    }
  });

  console.log(supportTicketList)
  
  const Rows = filteredTickets.map((supportTicket) => {
    return (
      <SupportCard
        key={supportTicket.id}
        supportTicket={supportTicket}
        listView={listView}
        updateSupportTicket={updateSupportTicket}
        removeSupportTicket={removeSupportTicket}
      />
    );
  });
  
  return (
    <div
      className="AnnouncementsLogContainer"
      style={
        width <= cPhoneWidthLim || height <= cPhoneWidthLim
          ? {}
          : { position: "relative", left: "44px", paddingRight: "44px" }
      }
    >
      <header className="ManageUsersToolbar">
        <Toolbar>
          <h5>{t("support.supportPageName")}</h5>
        </Toolbar>
      </header>
  
      <div className="AnnouncementsListContainer supportListContainer">
        <div className="AnnouncementsListHeader supportHeader">
          {/* <ListToggle listView={listView} setListView={setListView} /> */}
          <div>
            <span
              className="k-icon k-font-icon k-i-inbox"
              style={{ marginRight: "5px", fontSize: "24px" }}
            />
            {supportTicketList.length}
          </div>
          <span
            className="k-icon k-font-icon k-i-information informationButton"
            style={{
              fontSize: "16px",
              marginLeft: "10px",
            }}
          />
        </div>
        <TabStrip selected={selectedTab} onSelect={handleTabSelect} className="supportTabs">
          <TabStripTab title={t("support.new")}>
            <div className="AnnouncementsListWrapper">
               {Rows}
            </div>
          </TabStripTab>
          <TabStripTab title={t("support.allOpen")}>
            <div className="AnnouncementsListWrapper">
                <div className="AnnouncementsListItemWrapper">{Rows}</div>
            </div>
          </TabStripTab>
          <TabStripTab title={t("support.inProgress")}>
            <div className="AnnouncementsListWrapper">
                <div className="AnnouncementsListItemWrapper">{Rows}</div>
            </div>
          </TabStripTab>
          <TabStripTab title={t("support.resolved")}>
            <div className="AnnouncementsListWrapper">
                <div className="AnnouncementsListItemWrapper">{Rows}</div>
            </div>
          </TabStripTab>
        </TabStrip>
      </div>
    </div>
  );
}