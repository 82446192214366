import { response } from "express";
import { supportTicketMessageType } from "../../store/user/types";
import rest from "./../../rest";
import { error } from "console";

export const getSupportTickets = (
    // occurredAt: Date | null,
    // stepsToReproduce: string,
    // category: string,
    // machineID: string,
    // operatorID: string,
    // versionSW: string,
    // machineType: string,
    // lastCalibratedAt: Date,
    // systemCreatedOn: string,
    // temperature: Number,
    // positionLat: string,
    // positionLong: string,
    // sensorStatus: string,
    // installationType: string,
) => {
    return new Promise<supportTicketMessageType[]>(async (resolve, reject) => {

        rest
          .get(`/supportTickets`)
          .then(async (response) => {
            const supportTickets: supportTicketMessageType[] = response.data.list;
            resolve(
                supportTickets.map((msg) => {
                    return { ...msg, sentToServer: true };
                })
            );
          })
          .catch((error) => {
            const err = `Failed to fetch support tickets from server: ${error}`;
            console.error(err);
            reject(err);
          });
    });
};

export const patchSupportTicket = (ticketId: string, supportTicketToPatch: Partial<supportTicketMessageType>) => {
  return new Promise<supportTicketMessageType>((resolve, reject) => {
      console.log(`Patching support ticket with ID: ${ticketId}`);

      rest
          .patch(`/supportTickets/${ticketId}`, supportTicketToPatch, { withCredentials: true })
          .then((response) => {
              const updatedTicket: supportTicketMessageType = response.data;
              resolve(updatedTicket);
          })
          .catch((error) => {
              const err = `Failed to patch support ticket with ID: ${ticketId} - ${error}`;
              console.error(err);
              reject(err);
          });
  });
};

export const deleteSupportTicket = (ticketId: string) => {
  return new Promise<void>((resolve, reject) => {

      rest
          .delete(`/supportTickets/${ticketId}`, { withCredentials: true })
          .then(() => {
              console.log(`Support ticket with ID: ${ticketId} deleted successfully`);
              resolve();
          })
          .catch((error) => {
              const err = `Failed to delete support ticket with ID: ${ticketId} - ${error}`;
              console.error(err);
              reject(err);
          });
  });
};