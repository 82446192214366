import React, { useEffect, useState } from "react";
import "./style.scss";

import { Toolbar } from "../../components/toolbar";

import { UpdateUserForm } from "./../../../src/components/user/updateUserForm";

// Redux Stuff
import {
  logout,
  performLogout,
  setAllCompanyUsers,
  setNotification,
  setSelectedLanguage,
  setSelectedUser,
} from "./../../store/user/userSlice";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../store/store";
import { useNavigate } from "react-router-dom";
import { AppState } from "../../store/store";
import { AdminAnnouncement } from "../../components/announcement/announcement";
import {
  setAllPlans,
  setSelectedMeasurement,
  setSelectedPlan,
} from "../../store/scheduler/schedulerSlice";
import {
  setAllCharts,
  setSelectedChart,
} from "../../store/plot/plotSlice";
import { cPhoneWidthLim } from "../../app";
import * as filesAPI from "./../../api/netRail/files";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import { languageType } from "../../store/user/types";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { setAllCompanies } from "../../store/company/companySlice";
import { setAllMachines } from "../../store/machine/machineSlice";

export const UserSettings: React.FunctionComponent = () => {
  const currentUser = useSelector((state: AppState) => state.user.currentUser);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const width = useSelector((state: AppState) => state.scheduler.screenWidth);
  const height = useSelector((state: AppState) => state.scheduler.screenHeight);
  const [downloadingManual, setDownloadingManual] = useState<boolean>(false);

  useEffect(() => {
    dispatch(setSelectedUser(""));
    return () => {
      dispatch(setSelectedUser(""));
    };
  }, [dispatch]);

  // Listen to when user has finished login out (From dispatch(logout()))
  useEffect(() => {
    if (currentUser === undefined) {
    navigate("/login", { replace: true });
    }
  }, [currentUser, navigate]);

  return (
    <div
      className="userSettingsContainer"
      style={
        width <= cPhoneWidthLim || height <= cPhoneWidthLim
          ? {}
          : { position: "relative", left: "44px", paddingRight: "44px" }
      }
    >
      <header className="UserSettingsHeader">
        <Toolbar>
          <h5>{t("menu.userPageName")}</h5>
        </Toolbar>
      </header>

      <div className="ManageUserContainer">
        <div className="ManageUser">
          <AdminAnnouncement />
          {currentUser ? <UpdateUserForm user={currentUser} /> : <div> </div>}
          <div
            style={{
              paddingTop: "20px",
              paddingBottom: "30px",
            }}
          >
            <DropDownList
              className="BUTTON"
              label={t("user.language").toString()}
              style={{
                width: "100%",
                minWidth: "300px",
                boxShadow: "0 0 0 0",
              }}
              data={Object.values(languageType).map((Val) => Val)}
              defaultValue={i18n.language}
              onChange={(e) => {
                i18n.changeLanguage(e.value);
                dispatch(setSelectedLanguage(e.value));
              }}
            />
          </div>
          <div className="LogOutButtonContainer">
            <button
              className="LogOutButton BUTTON"
              disabled={downloadingManual}
              style={downloadingManual ? { opacity: 0.5 } : {}}
              onClick={async () => {
                setDownloadingManual(true);
                try {
                  await filesAPI.downloadManual();
                } catch (error) {
                  dispatch(
                    setNotification({
                      message: t("user.manualError"),
                      style: "error",
                      open: true,
                    })
                  );
                }
                setDownloadingManual(false);
              }}
            >
              {downloadingManual ? (
                <img
                  src="./icons/settings.svg"
                  alt=""
                  className="calibrationSpinner"
                />
              ) : (
                <>
                  {t("user.manual")}
                  <span
                    style={{ marginLeft: "20px", fontSize: "24px" }}
                    className="k-icon k-font-icon k-i-file-txt"
                  />
                </>
              )}
            </button>
          </div>
          <div
            className="LogOutButtonContainer"
            style={{ marginTop: "50px", paddingBottom: "50px" }}
          >
            <button
              className="LogOutButton BUTTON"
              onClick={() => {
                dispatch(performLogout());
                dispatch(setAllPlans([]));
                dispatch(setAllCompanyUsers([]));
                dispatch(setAllCompanies([]));
                dispatch(setAllMachines([]));
                // Clear important redux states:
                dispatch(setAllCharts([]));
                dispatch(setSelectedMeasurement("-1"));
                dispatch(setSelectedPlan("-1"));
                dispatch(setSelectedChart("1"));
                sessionStorage.removeItem("lastPlan");
                sessionStorage.removeItem("lastMeasurement");
              }}
            >
              {t("user.logout")}
              <span
                style={{ marginLeft: "20px", fontSize: "24px" }}
                className="k-icon k-font-icon k-i-logout"
              />{" "}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
