import rest from "./../../rest";
import {
  dataPointType,
  gpsPointType,
  objectTypes,
} from "../../store/plot/types";

export const getPlotData = (
  measurementID: string,
  from: number,
  to: number,
  res: number,
  signalType: string,
  extraFields: string[]
) => {
  return new Promise<dataPointType[]>(async (resolve, reject) => {
    console.log("measurementID", measurementID);
    rest
      .post(`/processed-measurements/${measurementID}`, {
        from: from,
        to: to,
        resolution: res,
        signalType: signalType,
        extraFields: extraFields,
      })
      .then(async (result) => {
        const points: dataPointType[] = result.data.map((pt: any) => {
          const resp = {
            ...pt,
            objects:
              typeof pt.objects === "string"
                ? [pt.objects]
                : pt.objects === undefined
                ? []
                : pt.objects,
          };
          return resp;
        }); // TODO VALIDATE THAT BACKWARDS COMPATIBILITY WORKS. WE CHANGED FROM STRINGS TO ARRAYS.
        resolve(points);
      })
      .catch((error) => {
        console.log("error", error);
        reject(error);
      });
  });
};

export const getGpsData = (
  measurementID: string,
  from: number,
  to: number,
  res: number,
  signalType: string,
  extraFields: string[]
) => {
  return new Promise<gpsPointType[]>(async (resolve, reject) => {
    rest
      .post(`/processed-measurements/${measurementID}`, {
        from: from,
        to: to,
        resolution: res,
        signalType: signalType,
        extraFields: extraFields,
      })
      .then(async (result) => {
        const points: gpsPointType[] = result.data.map((pt: any) => {
          return {
            ...pt,
            objects:
              typeof pt.objects === "string"
                ? [pt.objects]
                : pt.objects === undefined
                ? []
                : pt.objects,
          };
        }); // TODO VALIDATE THAT BACKWARDS COMPATIBILITY WORKS. WE CHANGED FROM STRINGS TO ARRAYS.

        resolve(points);
      })
      .catch((error) => {
        console.log("error", error);
        reject(error);
      });
  });
};

export const getGpsObjectData = (
  measurementID: string,
  from: number,
  to: number,
  res: number,
  signalType: string,
  extraFields: string[]
) => {
  return new Promise<gpsPointType[]>(async (resolve, reject) => {
    try {
      const result = await rest.post(`/processed-measurements/${measurementID}/objects`, {
        from: from,
        to: to,
        resolution: res,
        signalType: signalType,
        extraFields: extraFields,
      });

      if (!result.data) {
        resolve([]);
      }

      const points: gpsPointType[] = result.data.map((pt: any) => {
        return {
          ...pt,
          objects:
            typeof pt.objects === "string"
              ? [pt.objects]
              : pt.objects === undefined
              ? []
              : pt.objects,
        };
      });
      resolve(points);
    } catch (error) {
      console.log("error", error);
      reject(error);
    }
  });
};

export const getPlotMetaData = (measurementID: string, signalType: string) => {
  return new Promise<any>(async (resolve, reject) => {
    rest
      .post(
        `processed-measurements/${measurementID}/metadata?signalType=${signalType}`
      )
      .then(async (result) => {
        resolve(result.data);
      })
      .catch((error) => {
        console.log("error", error);
        reject(error);
      });
  });
};

export const getIntegratedData = (
  measurementID: string,
  from: number,
  to: number,
  res: number,
  signalType: string,
  extraFields: string[]
) => {
  return new Promise<dataPointType[]>(async (resolve, reject) => {
    rest
      .post(`/integrate-processed-measurements/${measurementID}`, {
        from: from,
        to: to,
        resolution: res,
        signalType: signalType,
        extraFields: extraFields,
      })
      .then(async (result) => {
        const points: dataPointType[] = result.data;
        resolve(points);
      })
      .catch((error) => {
        console.log("error", error);
        reject(error);
      });
  });
};
