// Header.tsx
import React from "react";
import { useTranslation } from "react-i18next";

interface HeaderProps {
  measurementNumber: number;
  createdAt: string;
  arrowImg: JSX.Element;
  reprocessTag: boolean;
}

const Header: React.FC<HeaderProps> = ({
  measurementNumber,
  createdAt,
  arrowImg,
  reprocessTag,
}) => {
  const { t } = useTranslation();

  return (
    <div
      className="body-1 CardHeaderItem"
      style={{
        color: "var(--primary-color)",
        marginLeft: "10px",
        display: "flex",
        flexDirection: "row",
        position: "relative",
        borderBottom: "2px solid var(--primary-color)",
      }}
    >
      <div style={{ fontWeight: "500", margin: "6px" }}>
        {reprocessTag ? `${t("common.measurement")} ${measurementNumber} (${createdAt}) R` : `${t("common.measurement")} ${measurementNumber} (${createdAt})`}
      </div>
      <div className="CardHeaderArrow">{arrowImg}</div>
    </div>
  );
};

export default Header;
