import { createSlice } from "@reduxjs/toolkit";
import { machineState } from "./types";
import { getI18n } from 'react-i18next';
import { setNotification } from "../user/userSlice";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { TFunction } from "i18next";
import { AppState } from "../store";
import rest from "../../rest";
import { machineType } from "./types";

const initialState: machineState = {
  currentMachine: undefined,
  allMachines: [
    {
      id: "1",
      hashKey: "asdfasdgf1",
      name: "BJM666",
      description: "",
      status: "",
      coordinates: {
        longitude: 1,
        latitude: 1,
        altitude: 1,
      },
      companyID: "6ba7b810-9dad-11d1-80b4-00c04fd420c5",
    },
  ],
  selectedMachine: "",
  machineHasChanged: false,
};

export const saveMachineToServer = (
  t: TFunction<"translation", "translation">,
  name: string,
  description: string,
  companyID: string,
  machineID?: string,
  sentToServer?: boolean,
  nameTag?: string
) => {
  return (dispatch: any, getState: () => AppState) => {
    if (!sentToServer) {
      rest
        .post(
          "/machines",
          {
            name: name,
            description: description,
            companyID: companyID,
            nameTag: nameTag,
          },
          { withCredentials: true }
        )
        .then((response) => {
          const machineID = response.data.id;

          if (machineID.length !== 0) {
            rest
              .get("/machines", { withCredentials: true })
              .then((response) => {
                const newMachine: machineType = response.data.list.find(
                  (machine: machineType) => machine.name === name
                );

                rest
                  .get("/machines/" + newMachine.id, {
                    withCredentials: true,
                  })
                  .then((machineResponse) => {
                    dispatch(
                      setAllMachines(
                        getState()
                          .machine.allMachines.filter(
                            (machine) => machine.name !== ""
                          )
                          .concat({
                            ...response.data.list.find(
                              (machine: machineType) => machine.name === name
                            ),
                            hashKey: machineResponse.data.hashKey,
                          })
                      )
                    );
                  })
                  .catch((reason) => console.log("Can't get machines", reason));
              })
              .catch((error) => {
                console.log(error);
                dispatch(setMachineHasChanged(true));
              });
          }
          dispatch(setSelectedMachine(machineID));
          dispatch(setMachineHasChanged(false));
          dispatch(
            setNotification({
              style: "success",
              message: t("store.machine.machineAdded"),
              open: true,
            })
          );
        })
        .catch((error) => {
          if (error.message === "Network Error") {
            alert(t("store.networkError"));
          } else if (error.message === "Request failed with status code 409") {
            dispatch(
              setNotification({
                style: "error",
                message: t("store.machine.machineExists"),
                open: true,
              })
            );
          } else {
            dispatch(
              setNotification({
                style: "error",
                message: t("statusProgress.unexpectedError"),
                open: true,
              })
            )
          }

          dispatch(setMachineHasChanged(true));
        });
    } else if (sentToServer) {
      rest
        .patch(
          "/machines/" + machineID,
          {
            name: name,
            description: description,
            companyID: companyID,
            nameTag: nameTag,
          },
          { withCredentials: true }
        )
        .then((response) => {
          dispatch(
            updateMachineProperty({
              name: name,
              description: description,
            })
          );
          dispatch(setMachineHasChanged(false));
          dispatch(
            setNotification({
              style: "success",
              message: t("store.machine.machineUpdated"),
              open: true,
            })
          );
        })
        .catch((error) => {
          dispatch(setMachineHasChanged(true));
          if (error.message === "Network Error") {
            alert(t("store.networkError"));
          } else {
            dispatch(
              setNotification({
                style: "error",
                message: t("store.machine.machineUpdateError"),
                open: true,
              })
            );
          }
          console.log("error", error);
        });
    }
  };
};


export const removeMachine = createAsyncThunk(
  "machine/removeMachine",
  async (machineID: string, { dispatch }) => {
    try {
      const response = await rest.delete("/machines/" + machineID, { withCredentials: true });
      dispatch(deleteMachine(machineID));
      dispatch(setSelectedMachine(""));
    } catch (error) {
      console.error("Failed to delete machine with machineID ", machineID);
      const t = getI18n().t;
      dispatch(
        setNotification({
          style: "error",
          message: t("store.machine.deleteMachineError"),
          open: true,
        })
      );
    }
  }
);

export const machineSlice = createSlice({
  name: "machine",
  initialState,
  reducers: {
    setAllMachines: (state, action) => {
      state.allMachines = action.payload.map((machine: any) => {
        return { ...machine, sentToServer: true };
      });
      state.selectedMachine = "";
      state.machineHasChanged = false;
    },
    deleteMachine: (state, action) => {
      state.allMachines = state.allMachines.filter(
        (machine) => machine.id !== action.payload
      );
      state.selectedMachine = "";
    },
    setSelectedMachine: (state, action) => {
      state.selectedMachine = action.payload;
    },
    updateMachineProps: (state, action) => {
      state.allMachines = state.allMachines.map((machine) => {
        return machine.id === action.payload.id ? action.payload : machine;
      });
    },
    updateMachineProperty: (state, action) => {
      state.allMachines = state.allMachines.map((machine) => {
        return machine.id === state.selectedMachine
          ? {
            ...machine,
            ...action.payload,
            sentToServer: true,
          }
          : machine;
      });
    },
    addNewMachine: (state, action) => {
      state.allMachines.push(action.payload);
      state.selectedMachine = action.payload.id;
      state.machineHasChanged = true;
    },
    setMachineHasChanged: (state, action) => {
      state.machineHasChanged = action.payload;
    },
  },
});

export const {
  setAllMachines,
  deleteMachine,
  setSelectedMachine,
  updateMachineProps,
  updateMachineProperty,
  addNewMachine,
  setMachineHasChanged,
} = machineSlice.actions;

export default machineSlice.reducer;