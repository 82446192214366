import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { Input } from "@progress/kendo-react-inputs";
import { confirmSignIn, fetchAuthSession, signIn } from "aws-amplify/auth";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SignInSteps, errorCodes } from "../../pages/login/page";
import { useNavigate } from "react-router-dom";
import { setAuthenticationSuccess, updateCurrentUserProperty } from "../../store/user/userSlice";
import { useAppDispatch } from "../../store/store";
import { useSelector } from "react-redux";
import { AppState } from "../../store/store"

interface LoginFormProps {
  handleError: (
    err: any,
    setAuthError: React.Dispatch<React.SetStateAction<string>>,
    code?: number
  ) => void;
  setNextStep: React.Dispatch<React.SetStateAction<SignInSteps | undefined>>;
}

interface LoginFieldProps {
  password: string;
  email: string;
}

export const LoginForm: React.FunctionComponent<LoginFormProps> = ({
  handleError,
  setNextStep,
}) => {
  const { t } = useTranslation();
  const [authError, setAuthError] = useState("");
  const [hasBeenPressed, setHasBeenPressed] = useState(false);
  const initialValue = undefined; // TODO Handle user information
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  async function checkAuthSession() {
    try {
      const session = await fetchAuthSession();
      if (session.tokens) {
        dispatch(setAuthenticationSuccess(true));
      }
      
    } catch (error) {
      console.error("Error fetching the session:", error);
    }
  }
  checkAuthSession();

  const isAuthenticated = useSelector((state: AppState) => state.user.authenticationSuccess);
  if (isAuthenticated) {
    navigate("/")
  }

  const signInCallback = useCallback(
    async (dataItem: LoginFieldProps) => {
      try {
        const { isSignedIn, nextStep } = await signIn({
          username: dataItem.email,
          password: dataItem.password,
        });

        if (nextStep.signInStep === "CONTINUE_SIGN_IN_WITH_MFA_SELECTION") {
          // Trigger MFA
          const mfaType = "SMS"; // or TOTP
          const { isSignedIn, nextStep } = await confirmSignIn({
            challengeResponse: mfaType,
          });
        }

        setNextStep(nextStep.signInStep);
      } catch (err: any) {
        // setSpinner(false);
        if (err.name === "UserAlreadyAuthenticatedException") {
          /* This comes from the HMI. I think it triggers updating the current user from the API. I don't think it'll be necessary here. */
          // dispatch(updateCurrentUser()) 
          navigate("/");
          console.log("User already authenticated, redirecting to home page");
        }
        handleError(err, setAuthError, errorCodes.signInError);
      }
    },
    [handleError]
  );

  const form = (formRenderProps: FormRenderProps) => {
    return (
      <FormElement className="LoginForm">
        <fieldset className={"k-form-fieldset"}>
          <Field
            name={"email"}
            component={Input}
            label={t("loginPage.email")}
          // validator={(v) => emailValidator(t, v)}
          />
          <Field
            name={"password"}
            component={Input}
            type="password"
            label={t("loginPage.password")}
          // validator={(v) => passwordValidator(t, v)}
          />
          <div style={{ color: "red" }}>
            {authError && authError.length > 0 ? authError : ""}{" "}
          </div>
          <div className="k-form-buttons">
            <button
              type={"submit"}
              className="LoginButtonSmallMarginBot BUTTON"
              disabled={!formRenderProps.allowSubmit || hasBeenPressed}
              style={
                !formRenderProps.allowSubmit || hasBeenPressed
                  ? { opacity: 0.5 }
                  : {}
              }
            >
              {t("loginPage.login")}
            </button>
          </div>
          <div className="k-form-buttons">
            <button
              className="LoginResendMFAButton"
              type="button"
              onClick={(e) => {
                e.preventDefault();
                setNextStep("SHOW_FORGOT_PASSWORD_CONTENT");
              }}
            >
              {t("loginPage.resetPassword")}
            </button>
          </div>
        </fieldset>
      </FormElement>
    );
  };

  const handleSubmit = (dataItem: LoginFieldProps) => {
    setHasBeenPressed(true);
    setTimeout(() => {
      setHasBeenPressed(false);
    }, 1000);
    setAuthError("");
    signInCallback(dataItem);
  };

  return (
    <Form
      initialValues={initialValue}
      key={JSON.stringify(initialValue)}
      onSubmit={(dataItem) => {
        handleSubmit(dataItem as LoginFieldProps);
      }}
      render={form}
    />
  );
};
