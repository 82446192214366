import React, { useCallback, useEffect, useRef } from "react";
import "./chartContainer.css";

export interface ChartDimensionProps {
  width: number;
  height: number;
  offsetLeft: number;
  offsetTop: number;
}

interface ChartContainerProps {
  chartDimensions: ChartDimensionProps | undefined;
  setChartDimensions: React.Dispatch<
    React.SetStateAction<ChartDimensionProps | undefined>
  >;
  rerenderEvent?: boolean;
  children?: React.ReactNode;
}

export const ChartContainer: React.FunctionComponent<ChartContainerProps> = ({
  children,
  rerenderEvent,
  chartDimensions,
  setChartDimensions,
}) => {
  const chartContentRef = useRef<HTMLDivElement>(null);

  const handleResize = useCallback(() => {
    if (
      chartContentRef.current !== null &&
      (chartDimensions?.height !== chartContentRef.current.offsetHeight ||
        chartDimensions?.width !== chartContentRef.current.offsetWidth ||
        chartDimensions?.offsetLeft !== chartContentRef.current.offsetLeft ||
        chartDimensions?.offsetTop !== chartContentRef.current.offsetTop)
    ) {
      setChartDimensions({
        width: chartContentRef.current.offsetWidth,
        height: chartContentRef.current.offsetHeight,
        offsetLeft: chartContentRef.current.offsetLeft,
        offsetTop: chartContentRef.current.offsetTop,
      });
    }
  }, [setChartDimensions]);

  useEffect(() => {
    handleResize();
  }, [rerenderEvent, handleResize, chartContentRef.current?.offsetWidth]);

  useEffect(() => {
    // Remove potential old listeners
    window.removeEventListener("resize", handleResize);
    // Add new listeners
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  const renderChartContainer = () => {
    if (chartDimensions) {
      return children;
    } else {
      return null;
    }
  };

  return (
    <div className="ChartContainer" ref={chartContentRef} style={{height: '100%', width: '100%'}}>
      {renderChartContainer()}
    </div>
  );
};
